.service-section {
  max-width: 1400px;
  margin: 0 auto;
  padding: clamp(2rem, 5vw, 2rem);
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.service-section h2 {
  font-size: clamp(2.5rem, 6vw, 4rem);
  margin-bottom: 2rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  background: var(--gradient-primary-accent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.service-card {
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 2rem;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

[data-bs-theme="light"] .service-card {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.2),
              0.3em 0.3em 1em rgba(0,0,0,0.3);
}

[data-bs-theme="dark"] .service-card {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.05),
              0 0 0 2px rgba(255, 255, 255, 0.1),
              0.3em 0.3em 1em rgba(0,0,0,0.5);
}

.service-card:hover {
  transform: translateY(-5px);
}

[data-bs-theme="light"] .service-card:hover {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.3),
              0.4em 0.4em 1.2em rgba(0,0,0,0.4);
}

[data-bs-theme="dark"] .service-card:hover {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.07),
              0 0 0 2px rgba(255, 255, 255, 0.15),
              0.4em 0.4em 1.2em rgba(0,0,0,0.6);
}

.service-image {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  position: relative;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -webkit-user-drag: none;
  user-select: none;
  pointer-events: none;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: transparent;
  pointer-events: all;
}

.service-content {
  flex: 1;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 600;
}

.service-card h4 {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.service-card p {
  font-size: 1rem;
  color: var(--text-color);
  line-height: 1.6;
}

.button-wrapper {
  position: relative;
  display: inline-block;
}

.get-started-btn {
  padding: 0.75rem 3rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: 2px solid var(--primary-color);
  margin: 0 auto;
  display: block;
  pointer-events: none;
  transition: all 0.3s ease;
}

.button-overlay {
  position: absolute;
  inset: 0;
  background: transparent;
  pointer-events: all;
  cursor: pointer;
}

.button-wrapper:hover .get-started-btn {
  transform: translateY(-3px);
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-wrapper:active .get-started-btn {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .service-section {
    padding-top: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .service-section h2 {
    text-align: center;
    font-size: clamp(2.15rem, 4vw, 3rem);
  }

  .service-grid {
    grid-template-columns: 1fr;
  }

  .service-card {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .service-image {
    width: 100px;
    height: 100px;
  }

  .get-started-btn {
    width: 330px;
    padding: 0.8rem 1.5rem;
    font-size: 1.1rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .service-card {
    transition: none;
  }
  
  .service-card:hover {
    transform: none;
  }

  .get-started-btn {
    transition: none;
  }

  .get-started-btn:hover {
    transform: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .service-card {
    animation: fadeInUp 0.5s ease-out forwards;
    opacity: 0;
    transform: translateY(20px);
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .service-card:nth-child(1) { animation-delay: 0.1s; }
  .service-card:nth-child(2) { animation-delay: 0.3s; }
  .service-card:nth-child(3) { animation-delay: 0.5s; }
  .service-card:nth-child(4) { animation-delay: 0.7s; }
}