/* HireMeButton.css */

.hire-me-button {
  position: fixed;
  top: clamp(20px, 5vw, 30px);
  left: 375px;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  font-weight: 600;
  font-size: clamp(0.875rem, 2vw, 1rem);
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 1006;
  font-family: var(--font-family);
  line-height: var(--line-height);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* Light Mode Styles */
[data-bs-theme="light"] .hire-me-button {
  color: var(--text-color);
}

[data-bs-theme="light"] .hire-me-button:hover,
[data-bs-theme="light"] .hire-me-button:focus {
  color: var(--secondary-color);
  transform: translateX(-50%) translateY(-2px);
}

/* Dark Mode Styles */
[data-bs-theme="dark"] .hire-me-button {
  color: var(--text-color);
}

[data-bs-theme="dark"] .hire-me-button:hover,
[data-bs-theme="dark"] .hire-me-button:focus {
  color: var(--secondary-color);
  transform: translateX(-50%) translateY(-2px);
}

/* Style for the green dot */
.availability-dot {
  width: 0.625rem;
  height: 0.625rem;
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: inline-block;
  transition: all 0.3s ease;
}

/* Button text transition */
.hire-me-button span:last-child {
  transition: opacity 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hire-me-button {
    position: fixed;
    top: 20px;
    right: 50px;
    left: auto;
    transform: none;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .availability-dot {
    width: 0.5rem;
    height: 0.5rem;
  }
  
  [data-bs-theme="light"] .hire-me-button:hover,
  [data-bs-theme="light"] .hire-me-button:focus,
  [data-bs-theme="dark"] .hire-me-button:hover,
  [data-bs-theme="dark"] .hire-me-button:focus {
    transform: translateY(-2px);
  }
}

@media (max-width: 480px) {
  .hire-me-button {
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
  }
}