/* Reset default styles and set base styles */
:root {
  --font-family: 'Ubuntu', sans-serif;
  --heading-font-family: 'Space Grotesk', sans-serif;
  --line-height: 1.6;
  --base-font-size: 16px;
  --about-max-width: 1400px;
  --about-padding: clamp(1rem, 3vw, 2rem); /* Reduced side padding */
  --about-border-radius: 12px;
  --about-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --heading-font-size: clamp(2.5rem, 6vw, 4rem);
  --paragraph-font-size: clamp(1rem, 2vw, 1.25rem);
  --skills-heading-font-size: clamp(1.5rem, 4vw, 2.5rem);
  --skills-item-font-size: clamp(1rem, 2vw, 1.25rem);
  --skills-gap: 1rem;
  --skills-item-padding: 0.75rem 1.5rem;
  --skills-item-border-radius: 8px;
  --transition-duration: 0.3s;
  --greeting-gradient-light: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  --greeting-gradient-dark: linear-gradient(45deg, var(--primary-color), var(--accent-color));
}

/* Light mode variables */
[data-bs-theme="light"] {
  --background-color: #ededed;
  --text-color: #121212;
  --primary-color: #00379e;
  --secondary-color: #42e099;
  --accent-color: #ffa880;
  --secondary-background: #ededed;
  --greeting-gradient: var(--greeting-gradient-light);
}

/* Dark mode variables */
[data-bs-theme="dark"] {
  --background-color: #121212;
  --text-color: #ededed;
  --primary-color: #6198ff;
  --secondary-color: #1fbd76;
  --accent-color: #802800;
  --secondary-background: #0F0F0F;
  --greeting-gradient: var(--greeting-gradient-dark);
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: var(--base-font-size);
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  line-height: var(--line-height);
  overflow-x: hidden;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family);
}

.about-me {
  max-width: var(--about-max-width);
  margin: 0 auto;
  padding: var(--about-padding);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  min-height: 90vh; /* Adjusted from 100vh to 90vh */
  padding-top: 10rem; /* Added padding-top */
  padding-bottom: 5rem; /* Added padding-bottom */
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: -3rem; /* Move the about content section up a little */
}

.about-image {
  position: relative;
  overflow: hidden;
  border-radius: var(--about-border-radius);
  transition: transform 0.3s ease;
}

[data-bs-theme="light"] .about-image {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.2),
              0.3em 0.3em 1em rgba(0,0,0,0.3);
}

[data-bs-theme="dark"] .about-image {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.05),
              0 0 0 2px rgba(255, 255, 255, 0.1),
              0.3em 0.3em 1em rgba(0,0,0,0.5);
}

.about-image:hover {
  transform: translateY(-5px);
}

[data-bs-theme="light"] .about-image:hover {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.3),
              0.4em 0.4em 1.2em rgba(0,0,0,0.4);
}

[data-bs-theme="dark"] .about-image:hover {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.07),
              0 0 0 2px rgba(255, 255, 255, 0.15),
              0.4em 0.4em 1.2em rgba(0,0,0,0.6);
}

.about-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
  -webkit-user-drag: none;
  user-select: none;
  pointer-events: none;
}

.about-image:hover img {
  transform: scale(1.05);
}

.about-me h2 {
  font-size: var(--heading-font-size);
  font-weight: 700;
  letter-spacing: -0.02em;
  background: var(--greeting-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.about-me h3 {
  font-size: var(--skills-heading-font-size);
  color: var(--text-color);
  font-weight: 600;
}

.about-me p {
  font-size: var(--paragraph-font-size);
  line-height: var(--line-height);
  color: var(--text-color);
}

.education-list {
  list-style-type: none;
  padding: 0;
}

.education-list li {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: var(--secondary-background);
  border-radius: var(--about-border-radius);
  transition: transform 0.3s ease;
}

[data-bs-theme="light"] .education-list li {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.2),
              0.3em 0.3em 1em rgba(0,0,0,0.3);
}

[data-bs-theme="dark"] .education-list li {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.05),
              0 0 0 2px rgba(255, 255, 255, 0.1),
              0.3em 0.3em 1em rgba(0,0,0,0.5);
}

.education-list li:hover {
  transform: translateY(-5px);
}

[data-bs-theme="light"] .education-list li:hover {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.3),
              0.4em 0.4em 1.2em rgba(0,0,0,0.4);
}

[data-bs-theme="dark"] .education-list li:hover {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.07),
              0 0 0 2px rgba(255, 255, 255, 0.15),
              0.4em 0.4em 1.2em rgba(0,0,0,0.6);
}

.education-list h4 {
  font-size: var(--skills-item-font-size);
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.education-list p {
  font-size: var(--paragraph-font-size);
  color: var(--text-color);
}

@media (max-width: 768px) {
  .about-me {
    grid-template-columns: 1fr;
    gap: 1rem; /* Increased gap for better spacing on mobile */
    padding-top: 2rem; /* Reduced top padding */
    padding-bottom: 1rem; /* Added bottom padding for mobile */
  }

  .about-image {
    order: -1;
    margin-bottom: 1rem; /* Added margin below the image */
  }

  .about-content {
    margin-top: 0; /* Remove negative margin on mobile */
  }

  .education-list li {
    padding: 0.75rem;
  }

  .about-me h2 {
    font-size: clamp(2.15rem, 4vw, 3rem); /* Smaller font size for h2 on mobile */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .about-content > * {
    animation: fadeInLeft 0.5s ease-out forwards;
    opacity: 0;
    transform: translateX(-20px);
  }

  .about-image {
    animation: fadeInRight 0.5s ease-out forwards;
    opacity: 0;
    transform: translateX(20px);
  }

  @keyframes fadeInLeft {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeInRight {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .about-me h2 { animation-delay: 0.1s; }
  .about-me h3 { animation-delay: 0.3s; }
  .about-me p:nth-of-type(1) { animation-delay: 0.5s; }
  .education-container { animation-delay: 0.7s; }
  .about-image { animation-delay: 0.9s; }
}

/* Utility classes */
.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: transparent;
  pointer-events: all;
}
