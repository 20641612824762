/* Footer Styles */
.site-footer {
  padding: clamp(1rem, 5vw, 2.5rem);
  width: 100%;
  box-sizing: border-box;
  position: static;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family);
  line-height: var(--line-height);
  font-size: var(--base-font-size);
}

.footer-content {
  max-width: min(90%, 1600px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.footer-left, .footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.footer-message-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.footer-message {
  font-size: clamp(0.875rem, 2vw, 1rem);
  margin: 0;
  color: var(--text-color);
}

.footer-submessage {
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

[data-bs-theme="light"] .footer-submessage {
  background-image: var(--gradient-primary-accent);
}

[data-bs-theme="dark"] .footer-submessage {
  background-image: var(--gradient-primary-accent);
}

.social-icons {
  display: flex;
  gap: clamp(1rem, 3vw, 2rem);
}

.social-icons a {
  font-size: clamp(1.25rem, 3vw, 1.5rem);
  transition: color 0.3s ease, transform 0.3s ease;
  color: var(--text-color);
}

.social-icons a:hover {
  transform: scale(1.1);
}

[data-bs-theme="light"] .social-icons a:hover {
  color: var(--secondary-color);
}

[data-bs-theme="dark"] .social-icons a:hover {
  color: var(--secondary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .site-footer {
    padding: 2rem;
    position: relative;
    font-size: 14px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-left, .footer-right {
    width: 100%;
    align-items: center;
  }

  .footer-message-container {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    text-align: left;
  }

  .social-icons {
    margin-bottom: 5rem;
    gap: 50px;
    justify-content: center;
  }

  .footer-right {
    order: 1;
  }

  .footer-left {
    order: 2;
  }
}

@media (min-width: 769px) {
  .footer-content {
    flex-direction: row;
  }
  
  .footer-left {
    order: 1;
  }
  
  .footer-right {
    order: 2;
  }
}
