/* NavbarSide.css */

/* Common styles for navbar background */
.custom-navbar-background {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: auto;
  padding: 1rem;
  z-index: 1000;
  border-radius: 9999px;
  transition: all 0.3s ease;
}

/* Light Mode Styles */
[data-bs-theme="light"] .nav-link {
  color: var(--text-color);
}

[data-bs-theme="light"] .nav-link:hover {
  color: var(--secondary-color);
}

[data-bs-theme="light"] .nav-link.active {
  background: var(--gradient-primary-accent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Dark Mode Styles */
[data-bs-theme="dark"] .nav-link {
  color: var(--text-color);
}

[data-bs-theme="dark"] .nav-link:hover {
  color: var(--secondary-color);
}

[data-bs-theme="dark"] .nav-link.active {
  background: var(--gradient-primary-accent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Flexbox to keep nav items stacked vertically and aligned right */
.nav-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav-item {
  margin: 0.5rem 0;
  width: 100%;
  text-align: right;
}

.nav-link {
  display: inline-block;
  border: none;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  text-align: right;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  width: 100%;
  color: var(--text-color);
}

.nav-link.active {
  color: var(--accent-color);
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 0;
  top: 50%;
  right: 0;
  transition: all 0.3s ease;
}

.nav-link:hover::after {
  background-color: var(--secondary-color);
  height: 100%;
  top: 0;
}

.nav-link.active::after {
  background-image: var(--gradient-primary-accent);
  height: 100%;
  top: 0;
}

/* Mobile styles */
@media (max-width: 768px) {
  .custom-navbar-background {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: none;
    box-shadow: none;
    padding: 0;
    z-index: 1000;
    top: auto;
    transform: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .mobile-nav-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .active-section-label {
    margin-right: 10px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    display: inline-block;
    transition: opacity 0.3s ease, visibility 0.3s ease, background-image 0.3s ease;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  [data-bs-theme="light"] .active-section-label {
    background-image: var(--gradient-primary-accent);
  }

  [data-bs-theme="dark"] .active-section-label {
    background-image: var(--gradient-primary-accent);
  }

  .menu-open .active-section-label {
    opacity: 0;
    visibility: hidden;
  }

  .hamburger-menu {
    border: none;
    font-size: 2rem;
    color: var(--text-color);
    cursor: pointer;
    transition: transform 0.3s ease;
    background: none;
    padding: 0;
  }

  .nav-list {
    display: flex;
    position: absolute;
    bottom: 100%;
    right: 0;
    border-radius: 10px;
    padding: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .menu-open .nav-list {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  .nav-list.show {
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
  }

  .nav-item {
    margin: 0.25rem 0;
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .menu-open .nav-item {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.1s;
  }

  .nav-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.9rem;
  }

  .nav-link::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 0;
    top: 50%;
    right: 0;
    transition: all 0.3s ease;
  }

  .nav-link:hover::after {
    background-color: var(--secondary-color);
    height: 100%;
    top: 0;
  }

  .nav-link.active::after {
    background-image: var(--gradient-primary-accent);
    height: 100%;
    top: 0;
  }

  .hamburger-menu {
    border: none;
    font-size: 3rem;
    color: var(--text-color);
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .menu-open .hamburger-menu {
    transform: rotate(90deg);
  }

  [data-bs-theme="light"] .nav-link.active {
    background: var(--gradient-primary-accent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  [data-bs-theme="dark"] .nav-link.active {
    background: var(--gradient-primary-accent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

/* Dark mode adjustments for mobile */
@media (max-width: 768px) {
  [data-bs-theme="dark"] .hamburger-menu {
    color: var(--text-color);
  }
}
