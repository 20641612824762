/* Root Variables */
:root {
  --font-family: 'Ubuntu', sans-serif;
  --heading-font-family: 'Space Grotesk', sans-serif;
  --line-height: 1.6;
  --base-font-size: 16px;
  --greeting-gradient-light: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  --greeting-gradient-dark: linear-gradient(45deg, var(--primary-color), var(--accent-color));
}

/* Light Theme */
[data-bs-theme="light"] {
  --background-color: #ededed;
  --text-color: #121212;
  --primary-color: #00379e;
  --secondary-color: #42e099;
  --accent-color: #ffa880;
  --greeting-gradient: var(--greeting-gradient-light);
  --card-background: #ededed;
  --card-text: #121212;
  --ethereum-color: #6D24E0;
  --solana-color: #00FFA3;
  --cardano-color: #0033AD;
  --polkadot-color: #E6007A;
  --binance-color: #F0B90B;
  --tezos-color: #2C7DF7;
  --toggle-background: #ebf7fc;
  --toggle-button: #111;
  --toggle-text: #fff;
}

/* Dark Theme */
[data-bs-theme="dark"] {
  --background-color: #121212;
  --text-color: #ededed;
  --primary-color: #6198ff;
  --secondary-color: #1fbd76;
  --accent-color: #802800;
  --greeting-gradient: var(--greeting-gradient-dark);
  --card-background: #121212;
  --card-text: #ffffff;
  --ethereum-color: #6D24E0;
  --solana-color: #00FFA3;
  --cardano-color: #0033AD;
  --polkadot-color: #E6007A;
  --binance-color: #F0B90B;
  --tezos-color: #2C7DF7;
  --toggle-background: #2a2a2a;
  --toggle-button: #ededed;
  --toggle-text: #121212;
}

/* Layout Styles */
.welcome-section {
  max-width: 1400px;
  margin: 0 auto;
  padding: clamp(1rem, 3vw, 2rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  gap: 2rem;
}

.welcome-content {
  flex: 1;
  max-width: 600px;
}

.crypto-grid {
  flex: 1;
  max-width: 600px;
}

/* Responsive layout */
@media (max-width: 1200px) {
  .welcome-section {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2.5rem;
    min-height: auto;
  }

  .welcome-content,
  .crypto-grid {
    max-width: 100%;
  }
}

/* Typography Styles */
.responsive-greeting {
  font-family: var(--heading-font-family);
  font-size: clamp(2.5rem, 6vw, 4rem);
  font-weight: 700;
  letter-spacing: -0.02em;
  margin-bottom: 0.75rem;
  background: var(--greeting-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-align: left;
}

.responsive-title {
  font-family: var(--heading-font-family);
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-color);
  text-align: left;
}

.responsive-text {
  font-family: var(--font-family);
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: var(--line-height);
  margin-bottom: 0.75rem;
  color: var(--text-color);
  text-align: left;
}

/* Button Styles */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.button-wrapper {
  width: 100%;
}

.consultation-button,
.dm-button {
  width: 100%;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.consultation-button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: 2px solid var(--primary-color);
}

.dm-button {
  background-color: transparent;
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-height: 48px; /* Ensure consistent height */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Button Hover & Active States */
.consultation-button:hover,
.dm-button:hover {
  transform: translateY(-3px);
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.consultation-button:active,
.dm-button:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Animations */
@media (prefers-reduced-motion: no-preference) {
  .welcome-content > * {
    animation: fadeInLeft 0.5s ease-out forwards;
    opacity: 0;
    transform: translateX(-20px);
  }

  @keyframes fadeInLeft {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .responsive-greeting { animation-delay: 0.1s; }
  .responsive-title { animation-delay: 0.3s; }
  .responsive-text:nth-of-type(1) { animation-delay: 0.5s; }
  .responsive-text:nth-of-type(2) { animation-delay: 0.7s; }
  .button-container { animation-delay: 0.9s; }
}

/* Social Buttons */
.social-buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
}

@media (max-width: 768px) {
  .button-container {
    gap: 1rem;
  }

  .social-buttons {
    gap: 0.75rem;
  }

  .dm-button {
    font-size: 0.875rem; /* Slightly smaller font on mobile */
    padding: 0.75rem 1rem; /* Adjusted padding for mobile */
  }
}