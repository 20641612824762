/* Card Styles */
.crypto-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.card {
  width: clamp(150px, 20vw, 200px);
  height: clamp(220px, 30vw, 300px);
  background: var(--card-background);
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--card-text);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

[data-bs-theme="light"] .card {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.2),
              0.3em 0.3em 1em rgba(0,0,0,0.3);
}

[data-bs-theme="dark"] .card {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.05),
              0 0 0 2px rgba(255, 255, 255, 0.1),
              0.3em 0.3em 1em rgba(0,0,0,0.5);
}

.img {
  height: 40%;
  position: absolute;
  font-size: clamp(3rem, 4vw, 4rem);
  z-index: 1;
  transition: 0.2s ease-in-out;
}

.card:nth-child(1) .img { color: var(--ethereum-color); }
.card:nth-child(2) .img { color: var(--solana-color); }
.card:nth-child(3) .img { color: var(--cardano-color); }
.card:nth-child(4) .img { color: var(--polkadot-color); }
.card:nth-child(5) .img { color: var(--binance-color); }
.card:nth-child(6) .img { color: var(--tezos-color); }

.textBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.5rem;
  height: 100%;
  text-align: center;
  opacity: 0;
  z-index: 2;
  transition: 0.2s ease-in-out;
}

.textBox > .text {
  margin: 0;
  font-weight: bold;
}

.textBox > .head {
  font-size: clamp(14px, 1.8vw, 18px);
}

.textBox > .price {
  font-size: clamp(12px, 1.6vw, 16px);
  color: var(--secondary-color);
}

.textBox > span {
  font-size: clamp(10px, 1.4vw, 12px);
  margin-top: 0.25rem;
}

.textBox > .description {
  font-size: clamp(10px, 1.4vw, 14px);
  margin-top: 0.25rem;
  line-height: 1.2;
}

/* Card Hover Effects */
.card:hover {
  transform: scale(1.04) rotate(-1deg);
  border: 2px solid var(--secondary-color);
}

[data-bs-theme="light"] .card:hover {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.3),
              0.4em 0.4em 1.2em rgba(0,0,0,0.4);
}

[data-bs-theme="dark"] .card:hover {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.07),
              0 0 0 2px rgba(255, 255, 255, 0.15),
              0.4em 0.4em 1.2em rgba(0,0,0,0.6);
}

.card:hover > .img {
  height: 75%;
  filter: blur(7px);
  animation: anim 3s infinite;
}

.card:hover > .textBox {
  opacity: 1;
}

@keyframes anim {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .crypto-grid {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-padding: 1rem;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    padding: 3rem 0;
    padding-bottom: 1rem;
    margin: 3rem 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -1rem;
    gap: 0.5rem;
    mask-image: linear-gradient(
      to right,
      transparent,
      black 5%,
      black 95%,
      transparent
    );
    -webkit-mask-image: linear-gradient(
      to right,
      transparent,
      black 5%,
      black 95%,
      transparent
    );
  }

  .crypto-grid::after {
    content: '';
    padding-right: 0.5rem;
  }

  .crypto-grid::-webkit-scrollbar {
    /*     height: 4px;
    display: block;
  }

  [data-bs-theme="light"] .crypto-grid::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  [data-bs-theme="dark"] .crypto-grid::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  [data-bs-theme="light"] .crypto-grid::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #00379e, #ffa880);
    border-radius: 4px;
  }

  [data-bs-theme="dark"] .crypto-grid::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #6198ff, #802800);
    border-radius: 4px; */
    display: none;
  }

  .card {
    flex: 0 0 auto;
    width: 160px;
    height: 220px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    margin: 0;
    transform-origin: center center;
    transition: all 0.3s ease;
  }

  .card:first-child {
    margin-left: 0.5rem;
  }

  .card:not(:last-child) {
    margin-right: 0.5rem;
  }

  /* Scale effect for center card */
  .card:focus-within {
    transform: scale(1.05);
    z-index: 1;
  }
}

/* Optimize animations */
@media (prefers-reduced-motion: reduce) {
  .crypto-grid {
    scroll-behavior: auto;
  }
  
  .card {
    transition: none;
  }
}

@media (max-width: 1200px) {
  .crypto-grid {
    max-width: 100%;
  }
}