/* App.css */

/* Reset default styles and set base styles */
:root {
  --font-family: 'Ubuntu', sans-serif;
  --heading-font-family: 'Space Grotesk', sans-serif;
  --line-height: 1.6;
  --base-font-size: 16px;
}

/* Light mode variables */
[data-bs-theme="light"] {
  --background-color: #ededed;
  --text-color: #121212;
  --primary-color: #00379e;
  --secondary-color: #42e099;
  --accent-color: #ffa880;
  --gradient-primary-accent: linear-gradient(45deg, #00379e, #ffa880);
}

/* Dark mode variables */
[data-bs-theme="dark"] {
  --background-color: #121212;
  --text-color: #ededed;
  --primary-color: #6198ff;
  --secondary-color: #1fbd76;
  --accent-color: #802800;
  --gradient-primary-accent: linear-gradient(45deg, #6198ff, #802800);
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: var(--base-font-size);
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  line-height: var(--line-height);
  overflow-x: hidden;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family);
}

/* App container */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  :root {
    --base-font-size: 14px;
  }

  main {
    padding-bottom: 0rem;
  }
}

/* Utility classes */
.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}
