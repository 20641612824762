/* HeaderInfo.css */

.header-info {
  position: fixed;
  top: 25px;
  left: 100px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  height: 45px;
  width: 335px;
  z-index: 1005;
  transition: all 0.3s ease;
}

[data-bs-theme="light"] .header-info {
  background: rgba(237, 237, 237, 0.25);
}

[data-bs-theme="dark"] .header-info {
  background: rgba(18, 18, 18, 0.25);
}

.profile-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
  background-size: cover;
  background-position: center;
  -webkit-user-drag: none;
  position: relative;
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: transparent;
  pointer-events: all;
}

.name-title {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.72px;
  color: var(--text-color);
}

.title {
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

[data-bs-theme="light"] .title {
  background-image: var(--gradient-primary-accent);
}

[data-bs-theme="dark"] .title {
  background-image: var(--gradient-primary-accent);
}

@media (max-width: 600px) {
  .header-info {
    top: 10px;
    left: 10px;
    height: 50px;
    width: calc(100%);
    max-width: 370px;
  }

  .profile-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .name {
    font-size: 18px;
  }

  .title {
    font-size: 14px;
  }
}