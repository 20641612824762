:root {
  --font-family: 'Ubuntu', sans-serif;
  --heading-font-family: 'Space Grotesk', sans-serif;
  --line-height: 1.6;
  --base-font-size: 16px;
  --section-max-width: 1400px;
  --section-padding: clamp(1rem, 3vw, 4rem); /* Reduced side padding */
  --heading-font-size: clamp(2.5rem, 6vw, 4rem);
  --heading-margin-bottom: 2rem;
  --card-border-radius: 12px;
  --card-transition: all 0.4s ease;
  --image-height: 200px;
  --content-padding: 1.5rem;
  --title-font-size: clamp(1.25rem, 3vw, 1.75rem);
  --title-margin-bottom: 1rem;
  --description-font-size: clamp(0.9rem, 2vw, 1.1rem);
  --description-margin-bottom: 1.5rem;
  --button-padding: 0.75rem 1.5rem;
  --button-border-radius: 8px;
  --button-font-size: 0.9rem;
  --button-transition: all 0.3s ease;
  --greeting-gradient-light: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  --greeting-gradient-dark: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  --card-width: 600px;
  --card-height: 500px;
}

[data-bs-theme="light"] {
  --background-color: #ededed;
  --text-color: #121212;
  --primary-color: #0056b3;
  --secondary-color: #28a745;
  --accent-color: #ffa880;
  --card-bg: #ededed;
  --button-text-color: #ffffff;
  --greeting-gradient: var(--greeting-gradient-light);
}

[data-bs-theme="dark"] {
  --background-color: #121212;
  --text-color: #ededed;
  --primary-color: #4d94ff;
  --secondary-color: #2ecc71;
  --accent-color: #802800;
  --card-bg: #0F0F0F;
  --button-text-color: #121212;
  --greeting-gradient: var(--greeting-gradient-dark);
}

.insight-section {
  max-width: var(--section-max-width);
  margin: 0 auto;
  padding: var(--section-padding);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.insight-section h2 {
  font-size: var(--heading-font-size);
  margin-bottom: var(--heading-margin-bottom);
  font-weight: 700;
  letter-spacing: -0.02em;
  background: var(--greeting-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: var(--heading-font-family);
}

.insight-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--card-width));
  gap: 2rem;
  width: 100%;
  justify-content: center;
}

.insight-card {
  background-color: var(--card-bg);
  border-radius: var(--card-border-radius);
  overflow: hidden;
  transition: var(--card-transition);
  display: flex;
  flex-direction: column;
  position: relative;
  width: var(--card-width);
  height: var(--card-height);
}

[data-bs-theme="light"] .insight-card {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.2),
              0.3em 0.3em 1em rgba(0,0,0,0.3);
}

[data-bs-theme="dark"] .insight-card {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.05),
              0 0 0 2px rgba(255, 255, 255, 0.1),
              0.3em 0.3em 1em rgba(0,0,0,0.5);
}

.insight-card:hover {
  transform: translateY(-5px);
}

[data-bs-theme="light"] .insight-card:hover {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.3),
              0.4em 0.4em 1.2em rgba(0,0,0,0.4);
}

[data-bs-theme="dark"] .insight-card:hover {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.07),
              0 0 0 2px rgba(255, 255, 255, 0.15),
              0.4em 0.4em 1.2em rgba(0,0,0,0.6);
}

.insight-image-container {
  width: 100%;
  height: var(--image-height);
  position: relative;
  overflow: hidden;
}

.insight-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  user-select: none;
  pointer-events: none;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: transparent;
  pointer-events: all;
}

.insight-content {
  padding: var(--content-padding);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.insight-title {
  font-size: var(--title-font-size);
  margin-bottom: var(--title-margin-bottom);
  color: var(--text-color);
  font-family: var(--heading-font-family);
  font-weight: 600;
  text-align: left;
}

.insight-description {
  font-size: var(--description-font-size);
  line-height: var(--line-height);
  margin-bottom: var(--description-margin-bottom);
  color: var(--text-color);
  font-family: var(--font-family);
  flex-grow: 1;
  text-align: left;
}

.insight-footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.view-button {
  background-color: transparent;
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  width: fit-content;
  min-width: 150px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: var(--font-family);
}

.view-button:hover {
  transform: translateY(-3px);
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.view-button:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.view-all-insights-btn {
  background-color: transparent;
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  width: fit-content;
  min-width: 200px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 3rem;
  font-family: var(--font-family);
}

.view-all-insights-btn:hover {
  transform: translateY(-3px);
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.view-all-insights-btn:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .insight-section {
    padding-top: 0rem;
    padding-bottom: 5rem;
  }

  .insight-grid {
    grid-template-columns: 1fr;
  }
  
  .insight-card {
    width: 100%;
    height: auto;
    min-height: var(--card-height);
  }

  .view-button,
  .view-all-insights-btn {
    flex: 1;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .insight-card {
    animation: fadeInUp 0.6s ease-out forwards;
    opacity: 0;
    transform: translateY(30px);
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .insight-card:nth-child(1) { animation-delay: 0.1s; }
  .insight-card:nth-child(2) { animation-delay: 0.3s; }
  .insight-card:nth-child(3) { animation-delay: 0.5s; }
  .insight-card:nth-child(4) { animation-delay: 0.7s; }
  .view-all-insights-btn { 
    animation: fadeIn 0.6s ease-out 0.9s forwards;
    opacity: 0;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
}