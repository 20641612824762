:root {
  --font-family: 'Ubuntu', sans-serif;
  --heading-font-family: 'Space Grotesk', sans-serif;
  --line-height: 1.6;
  --base-font-size: 16px;
  --section-max-width: 1400px;
  --section-padding: clamp(1rem, 3vw, 4rem); /* Added back side padding */
  --heading-font-size: clamp(2.5rem, 6vw, 4rem);
  --heading-margin-bottom: 2rem;
  --card-border-radius: 12px;
  --card-transition: all 0.4s ease;
  --image-height: 200px;
  --content-padding: 1.5rem;
  --title-font-size: clamp(1.25rem, 3vw, 1.75rem);
  --title-margin-bottom: 1rem;
  --description-font-size: clamp(0.9rem, 2vw, 1.1rem);
  --description-margin-bottom: 1.5rem;
  --tech-stack-gap: 0.75rem;
  --tech-icon-size: 1.75rem;
  --tech-icon-transition: transform 0.3s ease, color 0.3s ease;
  --button-padding: 0.75rem 1.5rem;
  --button-border-radius: 8px;
  --button-font-size: 0.9rem;
  --button-transition: all 0.3s ease;
  --greeting-gradient-light: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  --greeting-gradient-dark: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  --tooltip-bg: rgba(0, 0, 0, 0.8);
  --tooltip-text: #ffffff;
  --card-width: 600px;
  --card-height: 500px;
  --links-gap: 1rem; /* Added explicit gap variable for links */
}

[data-bs-theme="light"] {
  --background-color: #ededed;
  --text-color: #121212;
  --primary-color: #0056b3;
  --secondary-color: #28a745;
  --accent-color: #ffa880;
  --card-bg: #ededed;
  --button-text-color: #ffffff;
  --greeting-gradient: var(--greeting-gradient-light);
}

[data-bs-theme="dark"] {
  --background-color: #121212;
  --text-color: #ededed;
  --primary-color: #4d94ff;
  --secondary-color: #2ecc71;
  --accent-color: #802800;
  --card-bg: #0F0F0F;
  --button-text-color: #121212;
  --greeting-gradient: var(--greeting-gradient-dark);
}

.expertise-section {
  max-width: var(--section-max-width);
  margin: 0 auto;
  padding: var(--section-padding);
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.expertise-section h2 {
  font-size: var(--heading-font-size);
  margin-bottom: var(--heading-margin-bottom);
  font-weight: 700;
  letter-spacing: -0.02em;
  background: var(--greeting-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-align: center;
  font-family: var(--heading-font-family);
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--card-width));
  gap: 2rem;
  width: 100%;
  justify-content: center;
}

.project-card {
  background-color: var(--card-bg);
  border-radius: var(--card-border-radius);
  overflow: hidden;
  transition: var(--card-transition);
  display: flex;
  flex-direction: column;
  position: relative;
  width: var(--card-width);
  height: var(--card-height);
  cursor: pointer;
}

[data-bs-theme="light"] .project-card {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.2),
              0.3em 0.3em 1em rgba(0,0,0,0.3);
}

[data-bs-theme="dark"] .project-card {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.05),
              0 0 0 2px rgba(255, 255, 255, 0.1),
              0.3em 0.3em 1em rgba(0,0,0,0.5);
}

.project-card:hover {
  transform: translateY(-5px);
}

[data-bs-theme="light"] .project-card:hover {
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0 0 2px rgba(190, 190, 190, 0.3),
              0.4em 0.4em 1.2em rgba(0,0,0,0.4);
}

[data-bs-theme="dark"] .project-card:hover {
  box-shadow: inset 0 -3em 3em rgba(255,255,255,0.07),
              0 0 0 2px rgba(255, 255, 255, 0.15),
              0.4em 0.4em 1.2em rgba(0,0,0,0.6);
}

.project-image-container {
  width: 100%;
  height: var(--image-height);
  position: relative;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  user-select: none;
  pointer-events: none;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: transparent;
  pointer-events: all;
}

.project-content {
  padding: var(--content-padding);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-content h3 {
  font-size: var(--title-font-size);
  margin-bottom: var(--title-margin-bottom);
  color: var(--text-color);
  font-family: var(--heading-font-family);
  font-weight: 600;
}

.project-content p {
  font-size: var(--description-font-size);
  line-height: var(--line-height);
  margin-bottom: var(--description-margin-bottom);
  color: var(--text-color);
  font-family: var(--font-family);
  flex-grow: 1;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: var(--tech-stack-gap);
  margin-bottom: var(--description-margin-bottom);
}

.tech-stack svg {
  font-size: var(--tech-icon-size);
  color: var(--primary-color);
  transition: var(--tech-icon-transition);
}

.tech-stack svg:hover {
  color: var(--secondary-color);
  transform: translateY(-3px);
}

.tech-icon-wrapper {
  position: relative;
}

.tech-icon-wrapper::before {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background-color: var(--tooltip-bg);
  color: var(--tooltip-text);
  font-size: 0.875rem;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  pointer-events: none;
  z-index: 1000;
}

.tech-icon-wrapper:hover::before {
  opacity: 1;
  visibility: visible;
}

.project-links {
  margin-top: auto;
  display: flex;
  gap: var(--links-gap);
  justify-content: center;
}

.project-links button {
  background-color: transparent;
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: var(--button-padding);
  width: fit-content;
  min-width: 150px;
  font-size: var(--button-font-size);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  transition: var(--button-transition);
  text-decoration: none;
  font-family: var(--font-family);
}

.project-links button:hover {
  transform: translateY(-3px);
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-links button:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
    padding: 0rem;
    padding-bottom: 2rem;
    gap: 2rem;
  }

  .project-card {
    width: 100%;
    margin: 0 auto;
    height: auto;
    min-height: var(--card-height);
  }

  .project-content {
    padding: 1.5rem;
  }

  .project-content h3 {
    font-size: 1.5rem;
  }

  .project-content p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .tech-stack {
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .tech-stack svg {
    font-size: 1.5rem;
  }

  .project-links {
    flex-direction: column;
    gap: 1rem;
  }

  .project-links button {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .expertise-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .project-card,
  .project-card:hover,
  .tech-stack svg:hover,
  .project-links button:hover,
  .tech-icon-wrapper::before {
    transition: none;
    transform: none;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .project-card {
    animation: fadeInUp 0.6s ease-out forwards;
    opacity: 0;
    transform: translateY(30px);
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .project-card:nth-child(1) { animation-delay: 0.1s; }
  .project-card:nth-child(2) { animation-delay: 0.3s; }
  .project-card:nth-child(3) { animation-delay: 0.5s; }
}