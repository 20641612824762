/* Theme Toggle Button Styles */
.theme-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 10px;
  z-index: 1007;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  color: var(--accent-color);
  border: none;
  background: transparent;
}

.theme-toggle-button:hover {
  color: var(--secondary-color);
  transform: scale(1.1);
}

.theme-toggle-button:hover .theme-text {
  color: var(--secondary-color);
}

/* Focus Styles for Accessibility */
.theme-toggle-button:focus {
  outline: none;
}

/* Theme-Specific Styles */
[data-bs-theme="light"] .theme-toggle-button {
  color: var(--primary-color);
}

[data-bs-theme="light"] .theme-toggle-button:hover {
  color: var(--secondary-color);
  transform: scale(1.1);
}

[data-bs-theme="dark"] .theme-toggle-button {
  color: var(--primary-color);
}

[data-bs-theme="dark"] .theme-toggle-button:hover {
  color: var(--secondary-color);
  transform: scale(1.1);
}

/* Theme Text Styles */
.theme-text {
  margin-left: 0.5rem;
  margin-top: -0.2rem;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.3s ease;
  font-family: var(--font-family);
}

[data-bs-theme="light"] .theme-text {
  color: var(--text-color);
}

[data-bs-theme="dark"] .theme-text {
  color: var(--text-color);
}

/* Responsive Adjustments for Theme Toggle Button */
@media (max-width: 768px) {
  .theme-toggle-button {
    top: 20px;
    right: 10px;
    padding: 0.3rem;
    border-radius: 8px;
    font-size: 1rem;
    background: transparent;
    z-index: 1010; /* Increased z-index for mobile */
  }

  .theme-toggle-button:hover {
    transform: scale(1.05);
  }

  [data-bs-theme="light"] .theme-toggle-button,
  [data-bs-theme="dark"] .theme-toggle-button {
    color: var(--primary-color);
  }

  [data-bs-theme="light"] .theme-toggle-button:hover,
  [data-bs-theme="dark"] .theme-toggle-button:hover {
    color: var(--primary-color);
  }

  .theme-text {
    display: none;
  }
}

/* Styled Toggle Switch */
.theme-mode-wrapper {
  position: fixed;
  top: 20px;
  right: 45px;
  z-index: 1000;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  --background: #C4C4C4;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  transition: .5s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 50%;
  left: 10%;
  bottom: 15%;
  box-shadow: inset 8px -4px 0px 0px #121212;
  background: var(--background);
  transition: .5s;
}

input:checked + .slider {
  background-color: #323232;
}

input:checked + .slider:before {
  transform: translateX(100%);
  box-shadow: inset 15px -4px 0px 15px #ededed;
}

@media (max-width: 768px) {
  .theme-mode-wrapper {
    top: 20px;
    right: 10px;
    z-index: 1010; /* Increased z-index for mobile */
  }

  .switch {
    font-size: 14px;
    width: 3em;
    height: 1.7em;
  }

  .slider:before {
    height: 1.2em;
    width: 1.2em;
  }
}
